var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass:
        "dapps--module-eth-block-info px-3 pb-13 pt-5 px-md-15 pb-md-15 pt-md-8",
    },
    [
      _vm.hasSearch
        ? _c("block-search", { staticClass: "mb-8 mb-md-5 mt-md-7" })
        : _c(
            "router-link",
            {
              staticClass: "blue500--text",
              attrs: { to: { name: _vm.ROUTES.MY_BLOCKS.NAME } },
            },
            [
              _c(
                "v-row",
                {
                  staticClass: "align-center mt-3 mb-7 mb-md-8",
                  attrs: { "no-gutters": "" },
                },
                [
                  _c(
                    "v-icon",
                    { staticClass: "bluePrimary--text", attrs: { size: "20" } },
                    [_vm._v(" mdi-chevron-left")]
                  ),
                  _c("div", [_vm._v("Back to all My blocks")]),
                ],
                1
              ),
            ],
            1
          ),
      !_vm.loading
        ? _c(
            "v-row",
            {
              staticClass: "justify-center mb-4 d-flex d-md-none align-center",
              attrs: { "no-gutters": "" },
            },
            [
              _c(
                "a",
                {
                  staticClass:
                    "border-container d-block pa-1 mx-auto cursor-image",
                  attrs: { href: _vm.handlerBlock.rawImg, target: "_blank" },
                },
                [
                  _c("v-img", {
                    attrs: {
                      "lazy-src": require("../assets/loading-block.svg"),
                      src: _vm.handlerBlock.img,
                      "max-width": _vm.$vuetify.breakpoint.xs ? "160" : "240",
                      contain: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "placeholder",
                          fn: function () {
                            return [
                              _c(
                                "v-row",
                                {
                                  staticClass: "fill-height ma-0",
                                  attrs: { align: "center", justify: "center" },
                                },
                                [
                                  _c("v-progress-circular", {
                                    attrs: {
                                      indeterminate: "",
                                      color: "disabledPrimary",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3444431515
                    ),
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      !_vm.loading
        ? _c(
            "v-row",
            {
              staticClass: "justify-center mt-md-5",
              attrs: { "no-gutters": "" },
            },
            [
              _c("div", { staticClass: "d-block" }, [
                _c(
                  "a",
                  {
                    staticClass:
                      "border-container d-none d-md-flex pa-1 mr-md-10 cursor-image",
                    attrs: { href: _vm.handlerBlock.rawImg, target: "_blank" },
                  },
                  [
                    _c("v-img", {
                      attrs: {
                        "lazy-src": require("../assets/loading-block.svg"),
                        src: _vm.handlerBlock.img,
                        "max-width": "332",
                        contain: "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "placeholder",
                            fn: function () {
                              return [
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "fill-height ma-0",
                                    attrs: {
                                      align: "center",
                                      justify: "center",
                                    },
                                  },
                                  [
                                    _c("v-progress-circular", {
                                      attrs: {
                                        indeterminate: "",
                                        color: "disabledPrimary",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3444431515
                      ),
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "v-col",
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-row align-center justify-space-between mb-4 mb-md-5",
                    },
                    [
                      _c("h2", { staticClass: "text-center text-md-left" }, [
                        _vm._v(
                          " Block #" + _vm._s(_vm.blockNumberFormatted) + " "
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "d-flex align-center" },
                        [
                          _c(
                            "share-network",
                            {
                              attrs: {
                                network: "twitter",
                                url:
                                  "https://www.ethvm.com/block/number/" +
                                  _vm.blockRef,
                                title: "Twitter message",
                                hashtags: "MyEtherWallet,MEW,EthBlocks",
                              },
                            },
                            [
                              _c(
                                "mew-button",
                                {
                                  staticClass: "mr-1",
                                  attrs: {
                                    "btn-style": "light",
                                    "color-theme": "basic",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex align-center" },
                                    [
                                      _c("v-icon", { attrs: { left: "" } }, [
                                        _vm._v("mdi-twitter"),
                                      ]),
                                      _c("div", [_vm._v("Share")]),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "mew-button",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                "btn-style": "light",
                                "color-theme": "basic",
                                disabled: "",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex align-center" },
                                [
                                  _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v("mdi-launch"),
                                  ]),
                                  _c("div", [_vm._v("View in Museum")]),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("block-info", {
                    attrs: {
                      "block-alert": _vm.alert,
                      "block-number": _vm.blockRef,
                      owner: _vm.alertOwner,
                      price: _vm.alertMintPrice,
                      "disable-action": _vm.isActionInProgress,
                      "estimated-total-wei": _vm.estimatedTotal,
                      "is-pending": _vm.hasPendingTx,
                      "has-enough-eth": _vm.hasEnoughEth,
                    },
                    on: {
                      mint: _vm.mintBlock,
                      openSend: _vm.openSendBlockOverlay,
                    },
                  }),
                  _c("div", { staticClass: "mt-10 mt-md-5" }, [
                    _c(
                      "div",
                      {
                        class: [
                          "textMedium--text",
                          { "mb-2": !_vm.isTestNetwork },
                        ],
                      },
                      [_vm._v(" " + _vm._s(_vm.handlerBlock.description) + " ")]
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "table-properties mt-10" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "table-properties--header py-3 px-5 textLight--text mew-caption",
                        },
                        [_vm._v(" properties ")]
                      ),
                      _vm._l(_vm.properties, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            class: [
                              {
                                "table-properties--row-colored":
                                  _vm.isEven(index),
                              },
                              {
                                "table-properties--last-row":
                                  index + 1 === _vm.properties.length,
                              },
                              "d-flex flex-row justify-space-between py-3 px-5 textDark--text ",
                            ],
                          },
                          [
                            _c("div", [_vm._v(_vm._s(item.text))]),
                            _c("div", [_vm._v(_vm._s(item.value))]),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.loading
        ? _c(
            "a",
            {
              staticClass: "d-flex flex-row-reverse mt-3",
              attrs: {
                href: "https://www.ethvm.com/block/number/" + _vm.blockRef,
                target: "_blank",
              },
              on: { click: _vm.trackToEthVM },
            },
            [_vm._v(" View block info on EthVM ")]
          )
        : _c("blocks-loading"),
      _c("block-send", {
        attrs: {
          open: _vm.openSendOverlay,
          close: _vm.closeSendOverlay,
          "block-number": _vm.blockNumberFormatted,
          "is-sending": _vm.isSending,
        },
        on: { send: _vm.sendBlock },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }