var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.blockRef === ""
        ? _c(
            "div",
            { staticClass: "pt-5 pb-13 px-3 px-sm-15 pb-sm-15 pt-md-8" },
            [
              _vm.loading
                ? _c("div", [_c("blocks-loading")], 1)
                : _c(
                    "v-row",
                    {
                      staticClass:
                        "align-center justify-start overview-container-my-blocks mb-5 mb-md-0",
                    },
                    [
                      _c(
                        "v-col",
                        {
                          class: [
                            "pt-3 pt-md-0 pb-0",
                            { "pt-2": _vm.hasBlocks },
                          ],
                          attrs: { cols: "12", md: "3" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "mew-heading-3 textDark--text" },
                            [
                              _vm._v(" My Blocks "),
                              _c("span", [
                                _vm._v(
                                  "(" +
                                    _vm._s(_vm.handlerMyBlocks.totalBlocks) +
                                    ")"
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _c("v-spacer", {
                        staticClass: "d-none d-md-flex",
                        attrs: { order: "2" },
                      }),
                      _vm.hasBlocks
                        ? _c(
                            "v-col",
                            {
                              staticClass: "pt-0 pb-2 pb-md-0",
                              attrs: { cols: "12", md: "3" },
                            },
                            [
                              _c("mew-search", {
                                attrs: {
                                  placeholder: "Find my block",
                                  "is-compact": "",
                                  value: _vm.filterBlock,
                                  "error-messages": _vm.filterErrorMessage,
                                },
                                on: { input: _vm.setFilter },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.hasBlocks
                        ? _c(
                            "v-col",
                            {
                              staticClass: "py-0 pb-md-0",
                              attrs: { cols: "12", md: "3" },
                            },
                            [
                              _c("blocks-sort", {
                                on: { setSort: _vm.setActiveSort },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
              !_vm.hasBlocks && !_vm.loading
                ? _c(
                    "v-row",
                    { staticClass: "mt-0" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pb-md-16 mb-md-16",
                          attrs: { cols: "12" },
                        },
                        [
                          _c("mew-alert", {
                            attrs: {
                              title: "You do not have any ETH Blocks",
                              description:
                                "If you recently minted or purchased an ETH Block, please wait until the transaction has been minted and come back. If you haven’t minted one, what are you waiting for? Mint a block!",
                              theme: "info",
                              "hide-close-icon": "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.hasBlocks && !_vm.loading
                ? _c(
                    "v-row",
                    {
                      staticClass:
                        "align-top justify-center justify-sm-start mt-0",
                    },
                    _vm._l(_vm.blocks, function (block) {
                      return _c(
                        "v-col",
                        {
                          key: block.blockNumber,
                          attrs: { cols: "9", sm: "6", md: "3" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "border-container px-5 pt-5 pb-8 fill-height",
                              on: {
                                click: function ($event) {
                                  return _vm.routeTo(block.blockNumber)
                                },
                              },
                            },
                            [
                              _c("v-img", {
                                attrs: {
                                  src: block.image,
                                  "lazy-src": require("../assets/loading-block.svg"),
                                  contain: "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "placeholder",
                                      fn: function () {
                                        return [
                                          _c(
                                            "v-row",
                                            {
                                              staticClass: "fill-height ma-0",
                                              attrs: {
                                                align: "center",
                                                justify: "center",
                                              },
                                            },
                                            [
                                              _c("v-progress-circular", {
                                                attrs: {
                                                  indeterminate: "",
                                                  color: "disabledPrimary",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "my-2 mew-heading-2 textDark--text",
                                },
                                [
                                  _vm._v(
                                    " Block #" +
                                      _vm._s(
                                        _vm.formatBlockNumber(block.blockNumber)
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _c("module-eth-block-info", {
            attrs: { "block-ref": _vm.blockRef, "has-search": false },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }