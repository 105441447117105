var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "dapps--eth-blocks--data-search pt-8 px-3 px-lg-12 pb-12 px-md-12",
    },
    [
      _c("block-search"),
      _c("div", { staticClass: "pt-12" }, [
        _vm.foundBlocks.length > 0
          ? _c(
              "div",
              _vm._l(_vm.foundBlocks, function (block, idx) {
                return _c("block-result-component", {
                  key: block.blockNumber,
                  attrs: {
                    "block-handler": block,
                    "is-loading": false,
                    "has-border": idx != _vm.foundBlocks.length - 1,
                  },
                })
              }),
              1
            )
          : _c(
              "div",
              _vm._l(3, function (block, idx) {
                return _c("block-result-component", {
                  key: "loadingBlockResult" + block,
                  attrs: { "is-loading": "", "has-border": idx != 2 },
                })
              }),
              1
            ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }