var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-sheet",
    {
      staticClass: "mew-component--white-sheet border-radius--10px",
      class: [
        _vm.sideinfo ? "sideinfo" : "",
        _vm.overflowHidden ? "overflow--hidden" : "",
        _vm.noShadow ? "" : "bs-wallet-block",
      ],
      attrs: {
        "max-width": _vm.maxWidth,
        "min-width": _vm.minWidth,
        width: _vm.fullWidth ? "100%" : "",
        color: "bgWalletBlock",
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }