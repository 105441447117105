var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "dapps--modules--module-eth-blocks-mint pt-5 pb-13 px-3 pa-sm-15",
    },
    [
      _c(
        "v-sheet",
        {
          staticClass: "mx-auto",
          attrs: { "max-width": "680", color: "bgWalletBlock" },
        },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c(
                  "div",
                  { staticClass: "text-center" },
                  [
                    _c("v-img", {
                      staticClass: "mt-8 mt-md-0",
                      attrs: {
                        src: require("../assets/eth-blocks-qrs.svg"),
                        height: "129",
                        contain: "",
                      },
                    }),
                    _c("div", { staticClass: "mt-5 mb-2 mew-heading-1" }, [
                      _vm._v("Find your ETH Block"),
                    ]),
                    _c("h4", { staticClass: "mb-8 textMedium--text" }, [
                      _vm._v(
                        " Own the unownable. Mint your very own unique QR NFT of a specific block on the Ethereum blockchain. From Genesis, to the block of your first crypto transaction, or the block that took you to the moon. Forge a piece of crypto history with a MEW ETH Blocks. "
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
              _c(
                "v-col",
                { attrs: { cols: "12", order: "first", "order-sm": "last" } },
                [_c("block-search")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }