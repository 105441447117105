var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dapps-stakewise-stake pt-8 pb-13 px-3 pa-sm-15" },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              class: _vm.$vuetify.breakpoint.smAndDown ? "my-10" : "pr-7",
              attrs: {
                order: _vm.$vuetify.breakpoint.smAndDown ? "last" : "",
                cols: "12",
                md: "8",
              },
            },
            [
              _c(
                "mew-sheet",
                { staticClass: "pa-15" },
                [
                  _c(
                    "div",
                    { staticClass: "mew-heading-2 textDark--text mb-8" },
                    [
                      _vm._v(
                        " Stake " +
                          _vm._s(_vm.currencyName) +
                          " with Stakewise "
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      ref: "input",
                      staticClass: "d-flex align-center text-center",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "border-radius--8px bgWalletBlockDark flex-grow-1 pa-5 d-flex flex-column align-center",
                          staticStyle: { width: "30%" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "mew-caption textMedium--text font-weight-regular mb-2",
                            },
                            [_vm._v(" You give ")]
                          ),
                          _c("div", { staticClass: "stake-icon" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/icons/icon-eth-gray.svg"),
                                alt: "Eth",
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "font-weight-bold mt-2" }, [
                            _vm._v("ETH"),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "px-5" },
                        [
                          _c("v-icon", { attrs: { color: "greenPrimary" } }, [
                            _vm._v("mdi-arrow-right"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "border-radius--8px bgWalletBlockDark flex-grow-1 pa-5 d-flex flex-column align-center",
                          staticStyle: { width: "30%" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "mew-caption textMedium--text font-weight-regular mb-2",
                            },
                            [_vm._v(" You will get ")]
                          ),
                          _c("div", { staticClass: "stake-icon" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/dapps/stakewise/assets/icon-stakewise-green.svg"),
                                alt: "Stakewise",
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "font-weight-bold mt-2" }, [
                            _vm._v("sETH2"),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "position--relative mt-15" },
                    [
                      _c("button-balance", {
                        attrs: { loading: false, balance: _vm.balanceInETH },
                      }),
                      _c("mew-input", {
                        attrs: {
                          type: "number",
                          "max-btn-obj": {
                            title: "Max",
                            disabled: !_vm.hasEnoughBalanceToStake,
                            method: _vm.setMax,
                          },
                          image: _vm.iconEth,
                          label: "Amount to stake",
                          placeholder: "Enter amount",
                          value: _vm.stakeAmount,
                          "error-messages": _vm.errorMessages,
                          "buy-more-str": _vm.buyMoreStr,
                        },
                        on: { buyMore: _vm.openBuySell, input: _vm.setAmount },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "stake-status" }, [
                    _c("div", { staticClass: "d-flex justify-space-between" }, [
                      _c("div", [
                        _c("div", { staticClass: "mew-body" }, [
                          _vm._v(" Network Fee "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "ml-2 greenPrimary--text cursor--pointer",
                              on: { click: _vm.openSettings },
                            },
                            [_vm._v(" Edit ")]
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "text-right" }, [
                        _c("div", {}, [
                          _vm._v(
                            _vm._s(_vm.ethTotalFee) +
                              " " +
                              _vm._s(_vm.currencyName)
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isEthNetwork,
                                expression: "isEthNetwork",
                              },
                            ],
                            staticClass: "mew-body textLight--text",
                          },
                          [_vm._v(" " + _vm._s(_vm.gasPriceFiat) + " ")]
                        ),
                      ]),
                    ]),
                  ]),
                  _c("v-divider", { staticClass: "mt-8" }),
                  _c(
                    "div",
                    {
                      staticClass: "mt-6",
                      on: {
                        click: function ($event) {
                          return _vm.$vuetify.goTo(_vm.target)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "font-weight-bold mb-2" }, [
                        _vm._v("How staking works"),
                      ]),
                      _c("ul", { staticClass: "textMedium--text" }, [
                        _c("li", { staticClass: "mb-2" }, [
                          _vm._v(
                            " Anyone can deposit any amount of " +
                              _vm._s(_vm.currencyName) +
                              " into the Stakewise pool. No minimum required. "
                          ),
                        ]),
                        _c("li", { staticClass: "mb-2" }, [
                          _vm._v(
                            " For each new deposit into the pool, Stakewise mints an equal amount of sETH2 (1 " +
                              _vm._s(_vm.currencyName) +
                              " = 1 sETH2). "
                          ),
                        ]),
                        _c("li", { staticClass: "mb-2" }, [
                          _vm._v(
                            " Holders of sETH2 will start accruing rETH2 rewards within 24 hours of receiving the sETH2 token. "
                          ),
                        ]),
                        _c("li", [
                          _vm._v("Stakewise takes 10% of the staking reward."),
                        ]),
                      ]),
                      _c("div", { staticClass: "mt-6" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://help.myetherwallet.com/en/articles/6136823-stake-your-eth-using-stakewise",
                              target: "_blank",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "greenPrimary--text" },
                              [
                                _vm._v(" View StakeWise guide"),
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "ml-2",
                                    attrs: { color: "greenPrimary", small: "" },
                                  },
                                  [_vm._v(" mdi-open-in-new ")]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _c("v-divider", { staticClass: "mt-9 mb-8" }),
                  _c(
                    "div",
                    { staticClass: "d-flex flex-column align-center" },
                    [
                      _c("mew-checkbox", {
                        attrs: {
                          label:
                            "I have read and agreed to Stakewise terms of\n    service.",
                          link: {
                            title: "Stakewise terms",
                            url: "https://stakewise.io/terms-and-conditions/",
                          },
                        },
                        model: {
                          value: _vm.agreeToTerms,
                          callback: function ($$v) {
                            _vm.agreeToTerms = $$v
                          },
                          expression: "agreeToTerms",
                        },
                      }),
                      _c("mew-button", {
                        staticClass: "mt-8",
                        attrs: {
                          title: "Start staking",
                          "btn-size": "xlarge",
                          disabled: !_vm.isValid,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.stake.apply(null, arguments)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "4" } },
            [
              _c("stakewise-apr", { staticClass: "mb-4" }),
              _c("stakewise-staking", {
                staticClass: "mb-4",
                attrs: {
                  "compound-rewards": "",
                  "tx-fee": _vm.txFee,
                  "has-enough-balance": _vm.hasEnoughBalance,
                },
                on: {
                  "set-max": _vm.setMax,
                  scroll: _vm.scroll,
                  "redeem-to-eth": _vm.redeemToEth,
                },
              }),
              _vm.isEthNetwork
                ? _c("stakewise-rewards", {
                    attrs: { "compound-rewards": "", "tx-fee": _vm.txFee },
                    on: {
                      "set-max": _vm.setMax,
                      scroll: _vm.scroll,
                      "redeem-to-eth": _vm.redeemToEth,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }